"use client"

import type { I18nLocale } from "@/modules/i18n/types"
import type { FunctionComponent, MouseEventHandler } from "react"

import Image from "next/image"
import { usePathname, useSearchParams } from "next/navigation"
import { useState } from "react"

import { Button } from "@jobteaser/spark/components/Button"
import { ChevronDown } from "@jobteaser/spark/components/Icons/ChevronDown"

import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { Dropdown } from "@/modules/layouts/components/Dropdown/Dropdown"
import { flags } from "@/modules/locales/flags"
import { useLocale } from "@/modules/locales/useLocale"
import { Link } from "@/modules/routing/components/Link"
import { getUpdatedHrefWithLocale } from "@/modules/routing/helpers/getUpdatedHrefWithLocale"

import styles from "./LocaleSwitcher.module.css"

type LocaleSwitcherProps = {
  className?: string
  locales: I18nLocale[]
  onLocaleClick?: () => void
}

export const LocaleSwitcher: FunctionComponent<LocaleSwitcherProps> = ({ className, locales, onLocaleClick }) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const locale = useLocale()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const href = `${pathname}?${searchParams.toString()}`
  const { t } = useTranslation(["module_header_fo", "shared_languages"])

  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    setIsOpen(!isOpen)
  }

  const handleClose = (): void => {}

  return (
    <div className={className}>
      <Button
        aria-label={t("module_header_fo.language")}
        className={styles.triggerButton}
        icon={ChevronDown}
        onClick={handleClick}
        variant="secondary"
      >
        <Image src={flags[locale]?.src} width="18" height="18" alt="" />
      </Button>
      {isOpen && (
        <Dropdown className={styles.dropdown} onClose={handleClose}>
          <ul className={styles.localesList}>
            {locales.map(localeToDisplay => (
              <li key={localeToDisplay}>
                <Button
                  className={styles.localeButton}
                  href={getUpdatedHrefWithLocale(href, locale, localeToDisplay as I18nLocale)}
                  disabled={isDisabled}
                  onClick={() => {
                    setIsDisabled(true)
                    onLocaleClick?.()
                  }}
                  renderComponent={Link}
                  size="small"
                  variant="tertiary"
                >
                  <Image src={flags[localeToDisplay]?.src} width="18" height="18" alt="" />
                  <span>{t(`shared_languages.${localeToDisplay}`)}</span>
                </Button>
              </li>
            ))}
          </ul>
        </Dropdown>
      )}
    </div>
  )
}
