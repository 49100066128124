import pDebounce from "p-debounce"
import { useEffect, useState } from "react"

export const useWindowWidth = (): number => {
  const isClient = typeof window !== "undefined"
  const [windowWidth, setWindowWidth] = useState(isClient ? window.innerWidth : 0)

  useEffect(() => {
    if (!isClient) return

    const onWindowResize = pDebounce(() => {
      setWindowWidth(window.innerWidth)
    }, 10)

    window.addEventListener("resize", onWindowResize)
    window.addEventListener("load", onWindowResize)

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener("resize", onWindowResize)
      window.removeEventListener("load", onWindowResize)
    }
  }, [setWindowWidth, isClient])

  return windowWidth
}

export const SMALL_BREAKPOINT = 480
export const MEDIUM_BREAKPOINT = 768
export const LARGE_BREAKPOINT = 1024
