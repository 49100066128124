import type { I18nLocale } from "@/modules/i18n/types"
import type { Profile, SchoolInformation } from "@/modules/profile/api/types"
import type { SchoolYear, StudyLevel } from "@/modules/profile/types"
import type { DropdownOption } from "@jobteaser/spark/dist/types/Dropdown"
import type { ChangeEventHandler, ComponentProps, FormEventHandler, FunctionComponent } from "react"

import { useState } from "react"

import { Button } from "@jobteaser/spark/components/Button"
import { Heading } from "@jobteaser/spark/components/Heading"
import { RadioGroup } from "@jobteaser/spark/components/RadioGroup"
import { SearchableMultiSelect } from "@jobteaser/spark/components/SearchableMultiSelect"
import { SearchableSelect } from "@jobteaser/spark/components/SearchableSelect"
import { Select } from "@jobteaser/spark/components/Select"
import { Text } from "@jobteaser/spark/components/Text"

import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { useLocale } from "@/modules/locales/useLocale"
import { useNotification } from "@/modules/notification/hooks"
import { updateSchoolYear } from "@/modules/profile/api/updateSchoolYear"
import { updateStudiesInfo } from "@/modules/profile/api/updateStudiesInfo"
import { updateStudyLevel } from "@/modules/profile/api/updateStudyLevel"
import { generateYearsOptions } from "@/modules/profile/utils/generateYearsOptions"

import styles from "./StudiesInfoForm.module.css"

type StudiesInfoFormProps = {
  allowMultipleCurriculums: boolean
  // TODO hide campus field for now - waiting for backend
  // campuses: SchoolInformation["campuses"]
  // currentCampus: number | null
  currentCurriculums: Profile["curriculums"]
  currentSchoolYear: SchoolYear["id"] | null
  currentStudyLevel: StudyLevel["id"] | null
  currentYearOfGraduation: number | null
  curriculums: SchoolInformation["curriculums"]
  firstName: Profile["firstName"]
  onSuccessfulSubmit?: ({ isAlumni, yearOfGraduation }: { isAlumni: boolean; yearOfGraduation: number }) => void
  schoolYears: SchoolYear[]
  studyLevels: StudyLevel[]
  isLocked?: boolean
  isStudentFirstTime?: boolean
}

type FormData = {
  curriculums: string[]
  schoolYearId?: string
  studyLevelId: string
  yearOfGraduation: number
}

async function saveData(
  { curriculums, schoolYearId, studyLevelId, yearOfGraduation }: FormData,
  locale: I18nLocale
): Promise<unknown[]> {
  return Promise.all([
    schoolYearId && updateSchoolYear({ locale, schoolYearId }),
    updateStudyLevel({ locale, studyLevelId }),
    updateStudiesInfo({
      locale,
      payloadData: {
        curriculums,
        yearOfGraduation,
      },
    }),
  ])
}

export const StudiesInfoForm: FunctionComponent<StudiesInfoFormProps> = ({
  allowMultipleCurriculums,
  // TODO hide campus field for now - waiting for backend
  // campuses,
  // currentCampus,
  currentCurriculums,
  currentSchoolYear,
  currentStudyLevel,
  currentYearOfGraduation,
  curriculums,
  firstName,
  onSuccessfulSubmit,
  schoolYears,
  studyLevels,
  isLocked,
  isStudentFirstTime,
}) => {
  const locale = useLocale()
  const { t } = useTranslation(["shared_modal", "dashboard_fo", "shared_error", "shared_select_input"])
  const showNotification = useNotification()
  const currentYear = new Date().getFullYear()

  const initialValues = {
    curriculums: currentCurriculums.map(({ uuid }) => uuid) || [],
    schoolYearId: currentSchoolYear || undefined,
    studyLevelId: currentStudyLevel || undefined,
    yearOfGraduation: `${currentYearOfGraduation}` || undefined,
  }

  const [values, setValues] = useState(initialValues)
  const [formFieldsError, setFormFieldsError] = useState<string[]>([])
  const [alumniStatus, setAlumniStatus] = useState(
    currentYearOfGraduation ? currentYearOfGraduation < currentYear : false
  )

  const { curriculums: selectedCurriculums, schoolYearId, studyLevelId, yearOfGraduation } = values
  const studyLevelOptions: ComponentProps<typeof Select>["options"] = studyLevels.map(({ id, name }) => ({
    label: name,
    type: "option",
    value: id,
  }))
  const hasStudyLevelInOptions = !!studyLevelOptions.find(item => item.type === "option" && item.value === studyLevelId)
  const schoolYearOptions: ComponentProps<typeof Select>["options"] = alumniStatus
    ? []
    : schoolYears
        .filter(({ parentId }) => parentId === studyLevelId)
        // eslint-disable-next-line id-length
        .sort((a, b) => a.order - b.order)
        .map(({ id, name }) => ({ label: name, type: "option", value: id }))
  const hasSchoolYearInOptions = !!schoolYearOptions.find(item => item.type === "option" && item.value === schoolYearId)
  const curriculumsOptions: DropdownOption[] = curriculums.map(({ uuid, title }) => ({
    label: title,
    type: "option",
    value: `${uuid}`,
  }))
  const curriculumsSelectedOptions = curriculumsOptions.filter(
    option => `value` in option && selectedCurriculums.includes(option.value)
  )

  const handleFieldChange = (name: keyof FormData, value: string | string[] | undefined): void => {
    setFormFieldsError([])

    const updatedValue = { ...values, [name]: Array.isArray(value) ? value.filter(Boolean) : value }

    if (name === "studyLevelId") {
      updatedValue.schoolYearId = undefined
    }

    setValues(updatedValue)
  }

  const handleAlumniStatusFieldChange: ChangeEventHandler<HTMLInputElement> = event => {
    setAlumniStatus(event.target.value === "true")
    handleFieldChange("yearOfGraduation", undefined)
  }

  const handleFormSubmission: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()

    const fieldsInError = []

    if (!!curriculums.length && !selectedCurriculums.length) {
      fieldsInError.push("curriculums")
    }

    if (!alumniStatus && !schoolYearId) {
      fieldsInError.push("schoolYearId")
    }

    if (!studyLevelId) {
      fieldsInError.push("studyLevelId")
    }

    if (!yearOfGraduation) {
      fieldsInError.push("yearOfGraduation")
    }

    if (fieldsInError.length) {
      setFormFieldsError(fieldsInError)
      return
    }

    saveData(
      {
        curriculums: selectedCurriculums,
        studyLevelId: `${studyLevelId}`,
        yearOfGraduation: Number(yearOfGraduation),
        ...(!alumniStatus && { schoolYearId: `${schoolYearId}` }),
      },
      locale
    )
      .then(() => {
        onSuccessfulSubmit?.({ isAlumni: alumniStatus, yearOfGraduation: Number(yearOfGraduation) })
      })
      .catch(() => {
        showNotification({
          title: t("shared_error.generic"),
          variant: "error",
        })
      })
  }

  const modalTitle = isStudentFirstTime
    ? t("dashboard_fo.onboarding_modal_new_student.title", { firstName })
    : t("dashboard_fo.onboarding_modal_returning_student.title", { firstName })

  const modalSubTitle = isStudentFirstTime
    ? t("dashboard_fo.onboarding_modal_new_student.subtitle")
    : t("dashboard_fo.onboarding_modal_returning_student.subtitle")

  return (
    <form className={styles.main} onSubmit={handleFormSubmission}>
      <div className={styles.header}>
        <Heading variant="title2" as="h2">
          {modalTitle}
        </Heading>
        <Text variant="body2">{modalSubTitle}</Text>
      </div>
      <div className={styles.fields}>
        <RadioGroup
          className={styles.highlighted_choices}
          defaultValue={alumniStatus ? "true" : "false"}
          items={[
            { label: t("dashboard_fo.onboarding_modal.status.option_student"), value: "false" },
            { label: t("dashboard_fo.onboarding_modal.status.option_alumni"), value: "true" },
          ]}
          label={t("dashboard_fo.onboarding_modal.status.label")}
          name="isAlumni"
          onChange={handleAlumniStatusFieldChange}
          variant="highlighted"
        />
        <Select
          name="studyLevelId"
          id="studyLevelId"
          defaultValue={hasStudyLevelInOptions ? studyLevelId : undefined}
          errorMessage={
            formFieldsError.includes("studyLevelId") ? t("dashboard_fo.onboarding_modal.required_field") : undefined
          }
          label={
            alumniStatus
              ? t("dashboard_fo.onboarding_modal.study_level.label_alumni")
              : t("dashboard_fo.onboarding_modal.study_level.label_student")
          }
          onSelect={value => handleFieldChange("studyLevelId", value)}
          options={studyLevelOptions}
        />
        {!alumniStatus && (
          <Select
            name="schoolYearId"
            id="schoolYearId"
            defaultValue={hasSchoolYearInOptions ? schoolYearId : undefined}
            errorMessage={
              formFieldsError.includes("schoolYearId") ? t("dashboard_fo.onboarding_modal.required_field") : undefined
            }
            label={t("dashboard_fo.onboarding_modal.school_year.label")}
            onSelect={value => handleFieldChange("schoolYearId", value)}
            options={schoolYearOptions}
          />
        )}
        <Select
          name="yearOfGraduation"
          id="yearOfGraduation"
          defaultValue={yearOfGraduation}
          errorMessage={
            formFieldsError.includes("yearOfGraduation") ? t("dashboard_fo.onboarding_modal.required_field") : undefined
          }
          label={
            alumniStatus
              ? t("dashboard_fo.onboarding_modal.graduation_year.label_alumni")
              : t("dashboard_fo.onboarding_modal.graduation_year.label_student")
          }
          onSelect={value => handleFieldChange("yearOfGraduation", value)}
          options={generateYearsOptions(alumniStatus)}
        />
        {!!curriculums.length && !allowMultipleCurriculums && (
          <SearchableSelect
            name="curriculums"
            id="curriculums"
            defaultValue={curriculumsSelectedOptions[0] || undefined}
            errorMessage={
              formFieldsError.includes("curriculums") ? t("dashboard_fo.onboarding_modal.required_field") : undefined
            }
            label={t("dashboard_fo.onboarding_modal.courses.label")}
            onSelect={({ value }) => handleFieldChange("curriculums", [value])} // studies info API only accept an array for curriculums
            options={curriculumsOptions}
            placeholder={t("dashboard_fo.onboarding_modal.courses.placeholder_single")}
          />
        )}
        {!!curriculums.length && allowMultipleCurriculums && (
          <SearchableMultiSelect
            name="curriculums"
            id="curriculums"
            clearIconAriaLabel={t("shared_select_input.clear")}
            removeOptionIconAriaLabel={t("shared_select_input.remove_option")}
            emptyContent={t("shared_select_input.all_options_selected")}
            noOptionFound={t("shared_select_input.no_results")}
            defaultValue={curriculumsSelectedOptions}
            errorMessage={
              formFieldsError.includes("curriculums") ? t("dashboard_fo.onboarding_modal.required_field") : undefined
            }
            label={t("dashboard_fo.onboarding_modal.courses.label")}
            onRemoveOption={removedOption => {
              handleFieldChange(
                "curriculums",
                curriculumsSelectedOptions
                  .filter(({ value }) => value !== removedOption.value)
                  .map(({ value }) => value)
              )
            }}
            onSelect={(_, newValues) =>
              handleFieldChange(
                "curriculums",
                newValues.map(({ value }) => value)
              )
            }
            options={curriculumsOptions}
            placeholder={t("dashboard_fo.onboarding_modal.courses.placeholder_multiple")}
          />
        )}

        {/* // TODO hide campus field for now - waiting for backend
        {!!campuses.length && (
          <Select
            name="campus"
            id="campus"
            defaultValue={`${currentCampus}` || undefined}
            
            errorMessage={
              formFieldsError.includes("campus") ? t("dashboard_fo.onboarding_modal.required_field") : undefined
            }
            label={t("dashboard_fo.onboarding_modal.campus.label")}
            onSelect={value => handleFieldChange("campus", value)}
            options={campuses.map(({ id, name }) => ({ label: name, value: `${id}` }))}
          />
        )} */}
      </div>
      <div className={styles.footer}>
        <Button type="submit" disabled={isLocked}>
          {t("shared_modal.confirm")}
        </Button>
      </div>
    </form>
  )
}
