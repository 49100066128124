import type { NextRequest } from "@/modules/fetch/types"
import type { Resume } from "@/modules/profile/api/types"
import type { ChangeEventHandler, FunctionComponent } from "react"

import { useRef, useState } from "react"

import { Button } from "@jobteaser/spark/components/Button"
import { Heading } from "@jobteaser/spark/components/Heading"
import { Upload } from "@jobteaser/spark/components/Icons/Upload"
import { Text } from "@jobteaser/spark/components/Text"

import { DATE_FORMATS, getDate } from "@/modules/dates/dates"
import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { useLocale } from "@/modules/locales/useLocale"
import { useNotification } from "@/modules/notification/hooks"
import { updateOnboardingSeen } from "@/modules/profile/api/updateOnboardingSeen"
import { CurrentCv } from "@/modules/profile/components/CurrentCv/CurrentCv"
import { NewCv } from "@/modules/profile/components/NewCv/NewCv"
import { UploadFile } from "@/modules/profile/components/UploadFile/UploadFile"
import { checkUploadedFile } from "@/modules/profile/utils/checkUploadedFile"
import { handleNewCvUpload } from "@/modules/profile/utils/handleNewCvUpload"
import { useTrackGenericClickEvent } from "@/modules/tracking/useTrackGenericClickEvent"
import { useTracking } from "@/modules/tracking/useTracking"

import styles from "./CvStep.module.css"

type CvStepProps = {
  onClose: () => void
  areCongratulationsDisplayed: boolean
  userResume: Resume | null
  userToken: string | undefined
  isLocked: boolean
  req: NextRequest
  isAiValidationAllowed: boolean
}

export const CvStep: FunctionComponent<CvStepProps> = ({
  userResume,
  userToken,
  onClose,
  areCongratulationsDisplayed,
  isLocked,
  req,
  isAiValidationAllowed,
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [newCv, setNewCv] = useState<File | null>(null)
  const { t } = useTranslation(["dashboard_fo", "shared_upload"])
  const showNotification = useNotification()
  const locale = useLocale()
  const trackEvent = useTrackGenericClickEvent()
  const tracking = useTracking()

  const shouldDisplayCurrentCv = !!userResume && !newCv
  const shouldDisplayUploadCv = !userResume && !newCv
  const shouldDisplayNewCv = !!newCv

  let updatedAtDateFromCv

  if (userResume) {
    updatedAtDateFromCv = new Date(userResume.uploadUpdatedAt ?? "")
  }

  const formattedUpdatedAtDate =
    getDate({
      date: updatedAtDateFromCv,
      dateFormat: DATE_FORMATS.DEFAULT_DATE,
      locale,
      timeZone: "Europe/Paris",
    }) || new Date().toLocaleDateString()
  const updatedOnLabel = t("dashboard_fo.onboarding_modal.cv_step.current_cv.updated_on", {
    date: formattedUpdatedAtDate ?? "",
  })

  const handleNewCvIsUploaded = (file: File): void => {
    setNewCv(file)
    tracking?.track("resume_upload", {
      event_screen: userResume ? "onboarding_modal_upload_new_cv" : "onboarding_modal_upload_my_cv",
      file_size_in_bytes: file.size,
      service: "jobteaser",
      upload_origin: "dashboard",
    })
  }

  const handleCvStepClose = async (): Promise<void> => {
    if (userToken && newCv) {
      await handleNewCvUpload({ isAiValidationAllowed, locale, resume: newCv, userToken })
      showNotification({
        title: t("dashboard_fo.onboarding_modal.cv_step.notification.success"),
        variant: "success",
      })
    }

    await updateOnboardingSeen({ req })
    onClose()
  }

  const handleFileSelect: ChangeEventHandler<HTMLInputElement> = (event): void => {
    if (!event.currentTarget.files || event.currentTarget.files.length === 0) {
      return
    }

    const file = event.currentTarget.files[0]

    const { isFileNotAPdf, isFileSizeEmpty, isFileSizeExceeded } = checkUploadedFile(file)

    if (isFileSizeExceeded) {
      showNotification({
        title: t("shared_upload.max_file_size_error"),
        variant: "error",
      })
      return
    }

    if (isFileSizeEmpty) {
      showNotification({
        title: t("shared_upload.empty_file_error"),
        variant: "error",
      })
      return
    }

    if (isFileNotAPdf) {
      showNotification({
        title: t("shared_upload.accepted_extensions_error"),
        variant: "error",
      })
      return
    }

    handleNewCvIsUploaded(file)
  }

  return (
    <>
      <div className={styles.header}>
        {areCongratulationsDisplayed && (
          <Heading variant="body1">{t("dashboard_fo.onboarding_modal.cv_step.alumni_subtitle")}</Heading>
        )}
        <Heading variant="title2" as="h2">
          {t("dashboard_fo.onboarding_modal.cv_step.title")}
        </Heading>
      </div>

      <div className={styles.content}>
        {shouldDisplayCurrentCv && (
          <CurrentCv
            userResume={userResume}
            updatedOnLabel={updatedOnLabel}
            updatedOnDataTestId="dashboard-OnboardingModal__Step2__current_resume"
            formattedUpdatedAtDate={formattedUpdatedAtDate}
          />
        )}

        {shouldDisplayNewCv && (
          <NewCv
            cvFile={newCv}
            updatedOnLabel={updatedOnLabel}
            updatedOnDataTestId="dashboard-OnboardingModal__Step2__new_resume"
            isAiValidationAllowed={false}
          />
        )}

        <UploadFile
          buttonDataTestid="dashboard-OnboardingModal__Step2__adding_new_resume"
          buttonLabel={t("dashboard_fo.onboarding_modal.cv_step.upload_cv.button_label")}
          inputId="application-flow-resume"
          inputName="resume"
          isVisible={shouldDisplayUploadCv}
          onFileChange={handleNewCvIsUploaded}
          title={t("dashboard_fo.onboarding_modal.cv_step.upload_cv.heading")}
        />
        {isAiValidationAllowed && (
          <Text variant="caption1" className={styles.cvHelpText}>
            {t("dashboard_fo.onboarding_modal.cv_step.ai_validation_enabled")}
          </Text>
        )}
      </div>

      {(shouldDisplayCurrentCv || shouldDisplayNewCv) && (
        <div className={styles.footer}>
          <Button
            onClick={() => inputFileRef.current?.click()}
            variant="secondary"
            icon={Upload}
            className={styles.footer__button}
            disabled={isLocked}
          >
            <input type="file" ref={inputFileRef} accept="application/pdf" onChange={handleFileSelect} />
            {t("dashboard_fo.onboarding_modal.cv_step.secondary_button")}
          </Button>
          <Button variant="highlight" onClick={handleCvStepClose} className={styles.footer__button} disabled={isLocked}>
            {t("dashboard_fo.onboarding_modal.cv_step.highlighted_button")}
          </Button>
        </div>
      )}

      {shouldDisplayUploadCv && (
        <div className={styles.footer}>
          <Button
            onClick={() => {
              handleCvStepClose()
              trackEvent("onboarding_modal_cv_step_later_click")
            }}
            variant="tertiary"
            onBackground="light"
            disabled={isLocked}
          >
            {t("dashboard_fo.onboarding_modal.cv_step.later")}
          </Button>
        </div>
      )}
    </>
  )
}
