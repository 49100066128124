import type { NextRequest } from "@/modules/fetch/types"
import type { I18nLocale } from "@/modules/i18n/types"

import { getResultFromResponse } from "@/modules/apiRequests/handlers"
import { handleFetchError } from "@/modules/fetch/errorHandler"
import { fetcher } from "@/modules/fetch/fetcher"
import { toCamelCaseKeys } from "@/modules/format/object/toCamelCaseKeys"

import { UPDATE_USER_SCHOOL_YEAR_ENDPOINT } from "./constants"

type UpdateSchoolYearParams = {
  locale: I18nLocale
  req?: NextRequest
  schoolYearId: string
}

export const updateSchoolYear = async ({ locale, req, schoolYearId }: UpdateSchoolYearParams): Promise<unknown> => {
  return fetcher(UPDATE_USER_SCHOOL_YEAR_ENDPOINT, {
    nextRequest: req,
    requestInit: {
      body: JSON.stringify({ school_year_id: schoolYearId }),
      headers: {
        Accept: "application/json",
        "Accept-Language": locale,
      },
      method: "PUT",
    },
    serviceDomain: "KRAKEND_API",
  })
    .then(getResultFromResponse<any>({ transformResult: toCamelCaseKeys }))
    .catch(handleFetchError(UPDATE_USER_SCHOOL_YEAR_ENDPOINT))
}
