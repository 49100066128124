"use client"

import type { NextRequest } from "@/modules/fetch/types"
import type { I18nLocale } from "@/modules/i18n/types"
import type { Resume } from "@/modules/profile/api/types"
import type { ComponentProps, FunctionComponent } from "react"

import dynamic from "next/dynamic"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { useCallback, useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"

import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { Modal, prepareModalClosing } from "@/modules/layouts/components/Modal/Modal"
import { Stepper } from "@/modules/layouts/components/Stepper/Stepper"
import { LocaleSwitcher } from "@/modules/locales/components/LocaleSwitcher/LocaleSwitcher"
import { useLocale } from "@/modules/locales/useLocale"
import { CvStep } from "@/modules/onboarding/components/OnboardingModal/CvStep"
import { StudiesInfoForm } from "@/modules/onboarding/components/OnboardingModal/StudiesInfoForm"
import { useTracking } from "@/modules/tracking/useTracking"

import styles from "./OnboardingModal.module.css"

type Step = "studiesInfo" | "cv"

type OnboardingModalProps = ComponentProps<typeof StudiesInfoForm> & {
  locales: I18nLocale[]
  userResume: Resume | null
  userToken: string | undefined
  req: NextRequest
  isAiValidationAllowed: boolean
  isStudentFirstTime?: boolean
}

const DynamicConfettis = dynamic(() =>
  import("@/modules/layouts/components/Confettis/Confettis").then(mod => mod.Confettis)
)

const funnelId = uuidv4()
const TOTAL_STEPS = 2

export const OnboardingModal: FunctionComponent<OnboardingModalProps> = ({
  locales,
  userResume,
  userToken,
  req,
  isAiValidationAllowed,
  isStudentFirstTime,
  ...props
}) => {
  const locale = useLocale()
  const tracking = useTracking()

  const [isLocked, setIsLocked] = useState(false)
  const [areCongratulationsDisplayed, setAreCongratulationsDisplayed] = useState(false)
  const [currentStep, setCurrentStep] = useState<Step>("studiesInfo")
  const [completedSteps, setCompletedSteps] = useState<Step[]>([])
  const [isModalClosed, setIsModalClosed] = useState(false)

  const { t, ready } = useTranslation(
    [
      "shared_modal",
      "dashboard_fo",
      "shared_languages",
      "module_header_fo",
      "shared_error",
      "shared_upload",
      "shared_select_input",
    ],
    true
  )
  const { refresh, replace } = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const updatedSearchParams = new URLSearchParams(searchParams)
  const step = Number(searchParams.get("step"))

  const addCompletedStep = useCallback(
    (nextCompletedStep: Step): Step[] => {
      const allCompletedSteps = [...completedSteps, nextCompletedStep]
      setCompletedSteps(allCompletedSteps)

      return allCompletedSteps
    },
    [completedSteps]
  )

  const stepTracking = useCallback(
    (allCompletedSteps: Step[]): void => {
      const isCompletedOnboarding = currentStep === "cv" && allCompletedSteps.length === TOTAL_STEPS
      tracking?.track("funnel_result", {
        completed_steps: allCompletedSteps,
        funnel_id: funnelId,
        funnel_type: "returning_onboarding_modale" as "shortlist_prerequisites",
        output_status: isCompletedOnboarding ? "complete" : "incomplete",
        query_parameters: "",
        referrer: document.referrer,
        service: "dashboard",
        total_steps: TOTAL_STEPS,
        trackee_locale: locale,
        uri: window.location.href,
      })
    },
    [currentStep, locale, tracking]
  )

  const sendTracking = (): void => {
    const allCompletedSteps = addCompletedStep(currentStep)
    stepTracking(allCompletedSteps)
  }

  const leaveOnboarding = useCallback((): void => {
    stepTracking(completedSteps)
  }, [completedSteps, stepTracking])

  const handleModalClosing = (): void => {
    sendTracking()
    setIsLocked(true)
    setIsModalClosed(true)
    prepareModalClosing()
    replace(pathname)
    refresh()
  }

  useEffect(() => {
    setCurrentStep(step === 2 ? "cv" : "studiesInfo")
  }, [step])

  // Watch beforeunload event to be able to send tracking before leaving the modal
  useEffect(() => {
    window.addEventListener("beforeunload", leaveOnboarding)
    return () => {
      window.removeEventListener("beforeunload", leaveOnboarding)
    }
  }, [leaveOnboarding])

  if (!ready) {
    return null
  }

  if (isModalClosed) return null

  return (
    <>
      <Modal data-testid="onboarding__ReturningModal" isClosingLocked withCloseButton={false} isLocked={isLocked}>
        <div className={styles.head}>
          <Stepper
            label={t("shared_modal.step", { currentStep: currentStep === "cv" ? 2 : 1, stepCount: 2 })}
            step={currentStep === "cv" ? 2 : 1}
          />
          <LocaleSwitcher locales={locales} className={styles.localeSwitcher} onLocaleClick={() => setIsLocked(true)} />
        </div>
        {currentStep === "studiesInfo" && (
          <StudiesInfoForm
            {...props}
            onSuccessfulSubmit={({ isAlumni, yearOfGraduation }) => {
              const currentYear = new Date().getFullYear()
              const isCurrentOrLastYear = yearOfGraduation === currentYear || yearOfGraduation === currentYear - 1

              sendTracking()

              // shallow routing to avoid re-rendering the whole page
              updatedSearchParams.set("step", "2")
              const newPath = `${pathname}?${updatedSearchParams.toString()}`
              window.history.replaceState({}, "", newPath)

              setCurrentStep("cv")
              setAreCongratulationsDisplayed(isAlumni && isCurrentOrLastYear)
            }}
            isLocked={isLocked}
            isStudentFirstTime={isStudentFirstTime}
          />
        )}
        {currentStep === "cv" && (
          <CvStep
            userResume={userResume}
            userToken={userToken}
            onClose={handleModalClosing}
            areCongratulationsDisplayed={areCongratulationsDisplayed}
            isAiValidationAllowed={isAiValidationAllowed}
            isLocked={isLocked}
            req={req}
          />
        )}
      </Modal>
      {currentStep === "cv" && areCongratulationsDisplayed && <DynamicConfettis />}
    </>
  )
}
