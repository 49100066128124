import type { NextRequest } from "@/modules/fetch/types"

import { fetcher } from "@/modules/fetch/fetcher"

type UpdateOnboardingSeenParams = {
  req: NextRequest
}

export const updateOnboardingSeen = async ({ req }: UpdateOnboardingSeenParams): Promise<Response> => {
  const endpoint = "/api/student_profiles/v1/onboarding/seen"
  const hostname = req.headers?.host || ""

  return fetcher(endpoint, {
    hostname,
    nextRequest: req,
    requestInit: {
      method: "POST",
    },
    serviceDomain: "MONOLITH_API",
  })
}
