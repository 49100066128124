"use client"

import type { FunctionComponent } from "react"

import Script from "next/script"

import { MEDIUM_BREAKPOINT, useWindowWidth } from "@/modules/window/hooks/useWindowWidth"

type ZendeskScriptProps = {
  zendeskKey: string
}

export const ZendeskScript: FunctionComponent<ZendeskScriptProps> = ({ zendeskKey }) => {
  const currentWindowWidth = useWindowWidth()

  // Avoid rendering on server (0px) and on mobile
  if (!zendeskKey || !currentWindowWidth || (currentWindowWidth && currentWindowWidth < MEDIUM_BREAKPOINT)) {
    return null
  }

  return (
    <Script
      id="ze-snippet"
      src={`https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`}
      strategy="lazyOnload"
    />
  )
}
