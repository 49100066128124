import type { ObjectToCamel } from "@/modules/format/types"

import { isPureObject } from "@/modules/format/object/isPureObject"
import { toCamelCase } from "@/modules/format/string/toCamelCase"

export const toCamelCaseKeys = <T extends object | undefined | null>(item: T): ObjectToCamel<T> => {
  if (Array.isArray(item)) {
    return item.map(element => toCamelCaseKeys(element)) as ObjectToCamel<T>
  }

  if (isPureObject(item)) {
    return Object.fromEntries(
      Object.entries(item as Record<string, object | undefined | null>).map(
        ([key, value]: [string, object | undefined | null]) => [
          toCamelCase(key),
          isPureObject(value) || Array.isArray(value) ? toCamelCaseKeys(value) : value,
        ]
      )
    ) as ObjectToCamel<T>
  }

  return item as unknown as ObjectToCamel<T>
}
