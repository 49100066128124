import type { FunctionComponent, PropsWithChildren } from "react"

import { clsx } from "clsx"
import { useEffect, useRef, useState } from "react"

import styles from "./Dropdown.module.css"

export type DropdownProps = PropsWithChildren<{
  className?: string
  onClose: () => void
}>

export const Dropdown: FunctionComponent<DropdownProps> = ({ className, onClose, children }) => {
  /**
   * OPTIMIZE: create custom hook to handle outside click logic. To be used by CcFiltersDropdown as well
   */
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isOutsideClicked, setIsOutsideClick] = useState(false)

  const handleDocumentClick = (event: MouseEvent): void => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOutsideClick(true)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick)

    return () => {
      document.removeEventListener("click", handleDocumentClick)
    }
  }, [])

  useEffect(() => {
    if (isOutsideClicked) {
      onClose()
    }
  }, [onClose, isOutsideClicked])

  return (
    <div className={clsx(styles.main, className)} ref={dropdownRef}>
      {children}
    </div>
  )
}
