import type { FunctionComponent } from "react"

import { clsx } from "clsx"

import styles from "./Stepper.module.css"

type StepperProps = {
  className?: string
  label: string
  step: 1 | 2
}

export const Stepper: FunctionComponent<StepperProps> = ({ className, label, step }) => {
  return (
    <div
      className={clsx(styles.main, className)}
      aria-label={label}
      role="progressbar"
      aria-valuemin={1}
      aria-valuemax={2}
      aria-valuenow={step}
    >
      {step === 1 && (
        <>
          <div className={clsx(styles.step, styles.firstStep, styles.currentStep)} />
          <div className={clsx(styles.step, styles.secondStep, styles.futureStep)} />
        </>
      )}

      {step === 2 && (
        <>
          <div className={clsx(styles.step, styles.firstStep, styles.completedStep)} />
          <div className={clsx(styles.step, styles.secondStep, styles.currentStep)} />
        </>
      )}
    </div>
  )
}
