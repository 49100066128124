import type { I18nLocale } from "@/modules/i18n/types"

import { logger } from "@/modules/monitoring/logger"
import { uploadResume } from "@/modules/profile/api/uploadResume"

type HandleNewCvUploadParams = {
  resume: File | null
  locale: I18nLocale
  userToken: string
  isAiValidationAllowed: boolean
}

export const handleNewCvUpload = async ({
  resume,
  locale,
  userToken,
  isAiValidationAllowed,
}: HandleNewCvUploadParams): Promise<void> => {
  if (!resume) {
    // Better throw and having a kind of "500" page to avoid bad UI state
    throw Error("This should never happen!")
  }

  if (resume.size === 0) {
    const error = new Error(`Upload a new resume cancelled because the file seems to be empty.`)

    logger.error(error)
    throw error
  }

  const metadata: string[] = []

  metadata.push(`resume[name]=${resume.name}`)
  metadata.push(`resume[type]=${resume.type}`)
  metadata.push(`resume[size]=${resume.size}`)
  metadata.push(`resume[webkitRelativePath]=${resume.webkitRelativePath}`)

  try {
    await uploadResume({
      file: resume,
      hostname: window.location.host,
      isAiValidationAllowed,
      locale,
      method: "PUT",
      userToken,
    })
  } catch (error) {
    const errorMessage = new Error(
      `Upload a new resume failed reason=${(error as Error).message} cause=${
        (error as Error).cause ? JSON.stringify((error as Error).cause) : ""
      } // ${metadata.join(" // ")}`
    )
    logger.error(errorMessage)
    throw errorMessage
  }

  return undefined
}
