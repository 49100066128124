"use client"

import type { FormEvent, FunctionComponent, ReactNode } from "react"

import { useState } from "react"

import { Button } from "@jobteaser/spark/components/Button"
import { Checkbox } from "@jobteaser/spark/components/Checkbox"
import { LandscapeGreenOnlight } from "@jobteaser/spark/components/Logos/Jobteaser/LandscapeGreenOnlight"
import { Text } from "@jobteaser/spark/components/Text"

import { fetcher } from "@/modules/fetch/fetcher"
import { useLocale } from "@/modules/locales/useLocale"
import { logger } from "@/modules/monitoring/logger"

import styles from "./ApprovalPage.module.css"

const UPDATE_TERMS_CONDITIONS_ENDPOINT = "/v1/terms-and-conditions"

type ApprovalPageContent = {
  title: string
  explanation: string
  text: ReactNode
  validate: string
}

export const ApprovalPageContent: FunctionComponent<ApprovalPageContent> = props => {
  const { explanation, text, validate, title } = props

  const [checked, setChecked] = useState(false)
  const locale = useLocale()

  const handleCheck = (event: FormEvent<HTMLInputElement>): void => {
    setChecked(event.currentTarget.checked)
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    fetcher(UPDATE_TERMS_CONDITIONS_ENDPOINT, {
      requestInit: {
        body: JSON.stringify({ acceptCurrentTnC: true }),
        headers: {
          Accept: "application/json",
          "Accept-Language": locale,
        },
        method: "PUT",
      },
      serviceDomain: "KRAKEND_API",
    })
      .catch(error => {
        logger.error(
          `An error occurred while accepting the Terms & Conditions: ${error instanceof Error ? error.message : error}`
        )
        window.location.reload()
      })
      .finally(() => {
        window.location.reload()
      })
  }

  return (
    <main className={styles.wrapper} aria-live="assertive">
      <form className={styles.form} onSubmit={handleSubmit}>
        <LandscapeGreenOnlight aria-hidden />
        <div className={styles.block}>
          <Text variant="title2" as="h1">
            {title}
          </Text>
          <Text variant="body1" as="p">
            {explanation}
          </Text>
          <Checkbox onChange={handleCheck} className={styles.checkbox}>
            {text}
          </Checkbox>
          <div className={styles.ctaBlock}>
            <Button className={styles.button} type="submit" variant="highlight" disabled={!checked}>
              {validate}
            </Button>
          </div>
        </div>
      </form>
    </main>
  )
}
