import type { NextRequest } from "@/modules/fetch/types"
import type { I18nLocale } from "@/modules/i18n/types"

import { getResultFromResponse } from "@/modules/apiRequests/handlers"
import { handleFetchError } from "@/modules/fetch/errorHandler"
import { fetcher } from "@/modules/fetch/fetcher"
import { toCamelCaseKeys } from "@/modules/format/object/toCamelCaseKeys"
import { toSnakeCaseKeys } from "@/modules/format/object/toSnakeCaseKeys"

const UPDATE_STUDY_LEVEL_ENDPOINT = "/jobteaser.profile.v1alpha2/ProfileService/UpdateUserStudyLevel"
type UpdateStudyLevelParams = {
  locale: I18nLocale
  req?: NextRequest
  studyLevelId: string
}

export const updateStudyLevel = async ({ locale, req, studyLevelId }: UpdateStudyLevelParams): Promise<unknown> => {
  return fetcher(UPDATE_STUDY_LEVEL_ENDPOINT, {
    nextRequest: req,
    requestInit: {
      body: JSON.stringify(toSnakeCaseKeys({ studyLevelId })),
      headers: {
        Accept: "application/json",
        "Accept-Language": locale,
      },
      method: "POST",
    },
    serviceDomain: "APIGW_IN_KRAKEND_API",
  })
    .then(getResultFromResponse<any>({ transformResult: toCamelCaseKeys }))
    .catch(handleFetchError(UPDATE_STUDY_LEVEL_ENDPOINT))
}
