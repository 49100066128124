import type { ToCamel } from "@/modules/format/types"

export function toCamelCase<T extends string>(str: T): ToCamel<T> {
  const separator = /[_-\W]/gi
  const splittedString = str.split(separator).filter(Boolean)

  return splittedString.reduce((acc, word, index) => {
    if (splittedString.length < 2) {
      return `${acc}${word}`
    }

    const lowerWord = word.toLowerCase()
    const converted = index ? `${lowerWord.charAt(0).toUpperCase()}${lowerWord.slice(1)}` : lowerWord

    return `${acc}${converted}`
  }, "") as ToCamel<T>
}
