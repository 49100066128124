import type { Select } from "@jobteaser/spark/components/Select"
import type { ComponentProps } from "react"

const TIME_SINCE_FIRST_YEAR = 60
const TIME_UNTIL_LAST_YEAR = 20

/**
 * For the alumni user, we list the years from 60 years ago to the last year (excluding now).
 * For the student user, we list the years from now to 20 years in the future.
 */
export const generateYearsOptions = (isAlumni: boolean): ComponentProps<typeof Select>["options"] => {
  const currentYear = new Date().getFullYear()
  const firstYear = isAlumni ? currentYear - 1 : currentYear
  const lastYear = isAlumni ? currentYear - TIME_SINCE_FIRST_YEAR - 1 : currentYear + TIME_UNTIL_LAST_YEAR + 1
  const yearsCount = Math.abs(lastYear - firstYear)

  return new Array(yearsCount).fill(null).map((_, idx) => {
    const value = isAlumni ? firstYear - idx : firstYear + idx

    return {
      label: `${value}`,
      type: "option",
      value: `${value}`,
    }
  })
}
