import type { NextRequest } from "@/modules/fetch/types"
import type { I18nLocale } from "@/modules/i18n/types"

import { getResultFromResponse } from "@/modules/apiRequests/handlers"
import { handleFetchError } from "@/modules/fetch/errorHandler"
import { fetcher } from "@/modules/fetch/fetcher"
import { toCamelCaseKeys } from "@/modules/format/object/toCamelCaseKeys"

const UPDATE_STUDIES_INFO_ENDPOINT = "/jobteaser.studies_info.v1/StudiesInfoService/UpdateStudiesInfo"

type UpdateStudiesInfoParams = {
  payloadData: {
    yearOfGraduation: number
    curriculums: string[]
  }
  req?: NextRequest
  locale: I18nLocale
}

export const updateStudiesInfo = async ({ locale, req, payloadData }: UpdateStudiesInfoParams): Promise<unknown> => {
  const { curriculums, yearOfGraduation } = payloadData

  return fetcher(UPDATE_STUDIES_INFO_ENDPOINT, {
    nextRequest: req,
    requestInit: {
      body: JSON.stringify({
        curriculums: { uuids: curriculums },
        year_of_graduation: yearOfGraduation,
      }),
      headers: {
        Accept: "application/json",
        "Accept-Language": locale,
      },
      method: "POST",
    },
    serviceDomain: "APIGW_IN_KRAKEND_API",
  })
    .then(getResultFromResponse<any>({ transformResult: toCamelCaseKeys }))
    .catch(handleFetchError(UPDATE_STUDIES_INFO_ENDPOINT))
}
