import { createParseError, createRequestError, ensureError } from "@/modules/fetch/errorHandler"

/**
 * When useAPIErrorResponse is true, the helper will set the error cause with the response body.
 * Note that if the response is not a valid JSON, we create a JSON object with the text of the response.
 */
export const handleResponseNotOk = async (response: Response, useAPIErrorResponse: boolean = false): Promise<void> => {
  if (response.ok) return

  if (!useAPIErrorResponse) {
    throw createRequestError(response, `Response not ok (status : ${response.status})`)
  }

  let body
  try {
    body = await response.clone().json()
  } catch (_) {
    // do nothing
  }

  if (!body) {
    try {
      const text = await response.clone().text()
      if (text) {
        body = { error: text }
      }
    } catch (_) {
      // do nothing
    }
  }

  if (!body) {
    body = { error: response.statusText }
  }

  throw createRequestError(response, body)
}

type GetResultFromResponseOptions<ApiResponse, TransformedApiResponse, RedirectedApiResponse> = {
  onHttpRedirect?: () => RedirectedApiResponse
  transformResult?: (result: ApiResponse) => TransformedApiResponse
  useAPIErrorResponse?: boolean
}

// When there is no onHttpRedirect nor transformResult
export function getResultFromResponse<ApiResponse>(options?: {
  onHttpRedirect?: never
  transformResult?: never
  useAPIErrorResponse?: boolean
}): (response: Response) => Promise<ApiResponse>

// When there is only onHttpRedirect
export function getResultFromResponse<ApiResponse, RedirectedApiResponse>(options: {
  onHttpRedirect: () => RedirectedApiResponse
  transformResult?: never
  useAPIErrorResponse?: boolean
}): (response: Response) => Promise<ApiResponse | RedirectedApiResponse>

// When there is only transformResult
// TODO remove `= ApiResponse`
export function getResultFromResponse<ApiResponse, TransformedApiResponse = ApiResponse>(options: {
  onHttpRedirect?: never
  transformResult: (result: ApiResponse) => TransformedApiResponse
  useAPIErrorResponse?: boolean
}): (response: Response) => Promise<TransformedApiResponse>

// When there is onHttpRedirect and transformResult
export function getResultFromResponse<
  ApiResponse,
  // TODO remove `= ApiResponse`
  TransformedApiResponse = ApiResponse,
  // TODO remove `= ApiResponse`
  RedirectedApiResponse = ApiResponse
>(options: {
  onHttpRedirect: () => RedirectedApiResponse
  transformResult: (result: ApiResponse) => TransformedApiResponse
  useAPIErrorResponse?: boolean
}): (response: Response) => Promise<TransformedApiResponse | RedirectedApiResponse>

export function getResultFromResponse<ApiResponse, TransformedApiResponse, RedirectedApiResponse>(
  options?: GetResultFromResponseOptions<ApiResponse, TransformedApiResponse, RedirectedApiResponse>
) {
  return async (response: Response): Promise<ApiResponse | TransformedApiResponse | RedirectedApiResponse> => {
    const { useAPIErrorResponse = false } = options ?? {}

    await handleResponseNotOk(response, useAPIErrorResponse)

    if (response.status === 300 && options?.onHttpRedirect) {
      return options.onHttpRedirect()
    }

    const responseText = await response.clone().text()

    try {
      if (options?.transformResult) {
        return options.transformResult(await response.json())
      }

      return (await response.json()) as ApiResponse
    } catch (error) {
      throw createParseError(responseText, ensureError(error))
    }
  }
}
