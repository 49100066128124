import { isPureObject } from "@/modules/format/object/isPureObject"
import { toSnakeCase } from "@/modules/format/string/toSnakeCase"

export const toSnakeCaseKeys = <T>(item: unknown): T => {
  if (Array.isArray(item)) {
    return item.map((element: unknown) => toSnakeCaseKeys(element)) as T
  }

  if (isPureObject(item)) {
    return Object.fromEntries(
      Object.entries(item as Record<string, unknown>).map(([key, value]: [string, unknown]) => [
        toSnakeCase(key),
        isPureObject(value) || Array.isArray(value) ? toSnakeCaseKeys(value) : value,
      ])
    ) as T
  }

  return item as T
}
